import React from "react";
import { useBloc } from "@blac/react";
import simpleRnd from "lib/simpleRnd";
import { useEffect, useMemo } from "react";
import { Form } from "react-aria-components";
import { z } from "zod";
import AutoFormBloc, { AutoFormProps } from "./AutoFormBloc";
import AutoFormContext from "./AutoFormContext";

export default function AutoForm<S extends z.ZodObject<z.ZodRawShape>>(
  props: AutoFormProps<S>
) {
  const autoFormId = useMemo(() => simpleRnd(), []);
  const contextId = props.contextId ?? autoFormId;

  const [, { handleSubmit, setSchema }] = useBloc(AutoFormBloc<S>, {
    id: contextId,
    props
  });

  useEffect(() => {
    setSchema(props);
  }, [props.schema]);

  return (
    <AutoFormContext.Provider value={contextId}>
      <Form onSubmit={handleSubmit}>{props.children}</Form>
    </AutoFormContext.Provider>
  );
}
