/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
const LIB = "@9amhealth/shared";

export function warn(message: string, ...args: any[]): void {
  // do not include this log.ts file in error stack trace
  if (args.length) console.warn("DETAILS:\n", ...args);
  console.warn(
    `%c${LIB}
%c██╗    ██╗ █████╗ ██████╗ ███╗   ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝
%c
${message}${args.length > 0 ? "\n- See details in previous log message." : ""}
`,
    "color: #000; color: #fff",
    "color: #879623; font-family: monospace; font-size: 10px; font-weight: bold",
    undefined
  );
}

export function error(message: string, ...args: any[]): void {
  if (args.length) console.error("DETAILS:\n", ...args);
  console.error(
    `%c${LIB}
%c███████╗██████╗ ██████╗  ██████╗ ██████╗
██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
█████╗  ██████╔╝██████╔╝██║   ██║██████╔╝
██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗
███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║
╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝
%c
${message}${args.length > 0 ? "\n- See details in previous log message." : ""}

`,
    "color: #000; color: #fff",
    "color: #ff9623; font-family: monospace; font-size: 10px; font-weight: bold",
    undefined
  );
}
